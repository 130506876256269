import { Dialog as UiDialog, DialogPanel, DialogTitle } from '@headlessui/react'
import Button from '../Button/Button';

const ConfirmDialog = ({
  title = "",
  desc = "",
  isOpen = false,
  setIsOpen,
  cancelText = "Cancel",
  handleCancel,
  confirmText = "Proceed",
  handleConfirm
}) => {
  function close() {
    setIsOpen(false)
  }

  return (
    <UiDialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={close}>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-[#03030387]">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel
            transition
            className="w-full max-w-xl rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 flex flex-col items-center p-6 py-14 md:p-16"
          >
            {title && <DialogTitle as="h3" className="text-base/7 font-medium">
              {title}
            </DialogTitle>}
            <p className="mt-2 text-sm/6 ">
              {desc}
            </p>
            <div className="mt-8 flex items-center gap-4">
              <Button variant="white" onClick={() => {
                close();
                if (handleCancel) handleCancel()
              }}>{cancelText}</Button>
              <Button
                variant="yellow"
                onClick={handleConfirm}
              >
                {confirmText}
              </Button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </UiDialog>
  )
}

export default ConfirmDialog;
