import { adminRoute } from "../base";
import httpService from "../base/httpService";

export const getTalentsApi = async (page = 1, limit = 10, order = 'desc') => {
  const response = await httpService.get(`${adminRoute}/talents?page=${page}&limit=${limit}&order=${order}`);
  return response;
};

export const updateTalentApi = async (id = '', data) => {
  const response = await httpService.put(`${adminRoute}/update/talent/${id}`, data);
  return response;
};

export const deleteTalentApi = async (id = '') => {
  const response = await httpService.delete(`${adminRoute}/remove/talent/${id}`);
  return response;
};

export const deleteMultipleTalentApi = async (ids = []) => {
  const response = await httpService.post(`${adminRoute}/remove/bulk/talent/`, { ids });
  return response;
};

export const archiveTalentApi = async (id = '') => {
  const response = await httpService.post(`${adminRoute}/archive/talent/${id}`);
  return response;
};

export const unArchiveTalentApi = async (id = '') => {
  const response = await httpService.post(`${adminRoute}/unarchive/talent/${id}`);
  return response;
};
