import Button from "../../../../components/UI/Button/Button";
import classes from "./AdminManagement.module.css";
import Edit from "../../../../assets/images/icons/edit.svg";
import Delete from "../../../../assets/images/icons/delete.svg";
import { useCallback, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "../../../../redux/store";
import { getAdmins } from "../../../../redux/actions/adminActions";
import FullScreenLoader from "../../../../components/UI/FullScreenLoader/FullScreenLoader";
import { deleteAdminApi, deleteMultipleAdminApi } from "../../../../apis";
import { toast } from "react-toastify";
import { errorHandler } from "../../../../utils/errorHandler";
import ConfirmDialog from "../../../../components/UI/ConfirmDialog/ConfirmDialog";
import CheckInput from "../../../../components/UI/CheckInput/input";
import { handleFilterItems } from "../../../../utils";

const adminGroups = {
  super_admin: "Super Admin",
  admin: "Admin",
}

const AdminManagement = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [unSelectedItems, setUnSelectedItems] = useState([]);
  const { isLoading, admins } = useSelector(store => store.admin);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    sortField: null,
    sortOrder: null,
  });

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const handleGetAllRecords = useCallback(() => {
    dispatch(getAdmins(((lazyParams?.page || 0) + 1), lazyParams?.rows));
    setSelectedItem('');
    setSelectedItems([]);
  }, [dispatch, lazyParams?.page, lazyParams?.rows])

  const handleCheckAll = useCallback((e) => {
    setCheckAll(e.target.checked);
    setUnSelectedItems([])
    setSelectedItems([])
  }, []);

  const handleCheck = useCallback((id) => {
    if ((checkAll || selectedItems.includes(id))) {
      if (!unSelectedItems.includes(id)) {
        setUnSelectedItems(prev => [...prev, id]);
      }
      if (selectedItems.includes(id)) {
        setSelectedItems(prev => prev.filter(p => p !== id));
      }
    }
    if ((!checkAll || unSelectedItems.includes(id))) {
      if (unSelectedItems.includes(id)) {
        setUnSelectedItems(prev => prev.filter(p => p !== id));
      }
      if (!selectedItems.includes(id)) {
        setSelectedItems(prev => [...prev, id]);
      }
    }
  }, [checkAll, selectedItems, unSelectedItems]);

  const handleDeleteMultiple = useCallback(async () => {
    setLoading(true)
    try {
      if (checkAll || selectedItems.length > 0) {
        const items = checkAll ? handleFilterItems(admins?.records, unSelectedItems) : selectedItems;
        const { data } = await deleteMultipleAdminApi(items);
        setIsOpen(false)
        if (data) {
          toast.success(data.message || 'Records deleted successfully');
          handleGetAllRecords()
        }
      } else {
        toast.error("No record selected for this operation")
      }
    } catch (error) {
      errorHandler(error, error.errors ? Object.values(error.errors)[0] : '')
    }
    setLoading(false)
  }, [admins?.records, checkAll, handleGetAllRecords, selectedItems, unSelectedItems])

  const handleSelect = useCallback(async (id = '') => {
    setSelectedItem(id)
    setIsOpen(true)
  }, [])

  const handleDelete = useCallback(async () => {
    setLoading(true)
    try {
      if (selectedItem) {
        const { data } = await deleteAdminApi(selectedItem);
        setIsOpen(false)
        if (data) {
          toast.success(data.message || 'Record deleted successfully');
          handleGetAllRecords()
        }
      }
    } catch (error) {
      errorHandler(error, error.errors ? Object.values(error.errors)[0] : '')
    }
    setLoading(false)
  }, [handleGetAllRecords, selectedItem])

  const onLazyLoad = useCallback((event) => {
    setLazyParams(event);
  }, [setLazyParams]);

  useEffect(() => {
    handleGetAllRecords()
  }, [handleGetAllRecords])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {(isLoading || loading) && <FullScreenLoader />}

      <ConfirmDialog confirmText="Delete" handleConfirm={selectedItem ? handleDelete : handleDeleteMultiple} setIsOpen={setIsOpen} isOpen={isOpen} desc={`Are you sure you want to delete ${selectedItem ? 'this admin user' : 'these admin users'}?`} />

      <h2>Admin Management</h2>
      <div className={`mt-md ${classes.table}`}>
        <div className={`flex flex-col md:flex-row md:items-center gap-4 mb-md ${classes.btn}`}>
          <div className={`flex items-center ${classes.otherbutton}`}>
            <Button disabled={!checkAll && selectedItems.length === 0} onClick={() => setIsOpen(true)} variant="white">Delete</Button>
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center gap-4">
            <Button variant="yellow" isLink to="/dashboard/admin/admin-list/add">
              Add Admin
            </Button>
          </div>
        </div>

        <DataTable
          value={admins?.records || []}
          lazy
          paginator={admins.totalPages > 1}
          rows={lazyParams.rows}
          totalRecords={admins?.totalItems}
          loading={isLoading}
          onPage={onLazyLoad}
          first={lazyParams.first}
          emptyMessage="No record found"
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: '100%' }}
        >
          <Column className="pr-3 text-center"
            field="_id"
            header={
              <CheckInput type="checkbox" className={`w-[18px] h-[18px] rounded-s`} isIndeterminate={checkAll && unSelectedItems.length > 0} checked={checkAll} onChange={handleCheckAll} />}
            body={(item) => (
              <div className="text-left">
                <CheckInput type="checkbox" className="w-[18px] h-[18px] rounded-s" checked={(checkAll || selectedItems.includes(item._id)) && !unSelectedItems.includes(item._id)} onChange={() => handleCheck(item._id)} />
              </div>
            )}
          />
          <Column className="p-5 text-center"
            field="name"
            header="FULL NAME"
            body={(item) => (
              <div className="text-left min-w-[150px]">{`${item.firstName ?? ''} ${item.lastName ?? ''}`}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="email"
            header="EMAIL"
            body={(item) => (
              <div className={`${classes.subject} text-[#808080]`}>{item.email}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="admin_user_group"
            header="USER GROUP"
            body={(item) => (
              <div className="min-w-[100px]">{adminGroups[item.admin_user_group] || adminGroups.admin}
              </div>)}
          // sortable
          />
          <Column className="p-5"
            field="updatedAt"
            header="LAST UPDATED"
            body={(item) => (
              <div className="min-w-[100px]">{item.updatedAt ? new Date(item.updatedAt).toLocaleDateString() : ""}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="status"
            header="STATUS"
            body={(item) => (
              <div >
                {item.status}
              </div>)}
            sortable
          />
          <Column className="p-5" header="ACTIONS" body={(item) => (
            <div className="flex items-center gap-2 min-w-[85px]">
              <button className={`relative ${classes.edit}`} onClick={() => navigate(`/dashboard/admin/admin-list/edit`, { state: item })}>
                <img src={Edit} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Edit Admin
                    <span className={classes.arrowdown} ></span>
                  </span>
                </div>
              </button>
              <button onClick={() => handleSelect(item._id)} className={`relative ${classes.delete}`}>
                <img src={Delete} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Delete Admin
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
            </div>)} />
        </DataTable>
      </div>
    </div>
  );
};

export default AdminManagement;
