import { useEffect, useState } from "react";
import classes from "./switch.module.css";

const Switch = () => {
  const [checked, setChecked] = useState(true);

  return (
    <div className="">
      <div className={classes.switch}>
        <label for="toggleInput" onClick={() => setChecked(!checked)}>
          Verify Talent
        </label>
        <span>
          <input
            type="checkbox"
            id="toggleInput"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <button
            className={classes.slider}
            type="button"
            onClick={() => setChecked(!checked)}
          ></button>
        </span>{" "}
        <br />
      </div>
      <small className={classes.verified}>Verified</small>
    </div>
  );
};

export default Switch;
