import { adminRoute } from "../base";
import httpService from "../base/httpService";

export const getClientsApi = async (page = 1, limit = 10, order = 'desc') => {
  const response = await httpService.get(`${adminRoute}/clients?page=${page}&limit=${limit}&order=${order}`);
  return response;
};

export const updateClientApi = async (id = '', data) => {
  const response = await httpService.put(`${adminRoute}/update/client/${id}`, data);
  return response;
};

export const deleteClientApi = async (id = '') => {
  const response = await httpService.delete(`${adminRoute}/remove/client/${id}`);
  return response;
};

export const deleteMultipleClientApi = async (ids = []) => {
  const response = await httpService.post(`${adminRoute}/remove/bulk/client/`, { ids });
  return response;
};

export const archiveClientApi = async (id = '') => {
  const response = await httpService.post(`${adminRoute}/archive/client/${id}`);
  return response;
};

export const unArchiveClientApi = async (id = '') => {
  const response = await httpService.post(`${adminRoute}/unarchive/client/${id}`);
  return response;
};
