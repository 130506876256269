import { route } from "../base";
import httpService from "../base/httpService";

export const getEmailsApi = async (page = 1, limit = 10, order = 'desc') => {
  const response = await httpService.get(`${route}/email?page=${page}&limit=${limit}&order=${order}`);
  return response;
};

export const getCountriesApi = async (page = 1, limit = 10, order = 'desc') => {
  const response = await httpService.get(`https://countries-states.vercel.app/api/countries`);
  return response;
};

export const getSingleEmailApi = async (id = '', data) => {
  const response = await httpService.put(`${route}/email/template/${id}`, data);
  return response;
};

export const deleteEmailApi = async (id = '') => {
  const response = await httpService.delete(`${route}/email/template/${id}`);
  return response;
};

export const deleteMultipleEmailApi = async (ids = []) => {
  const response = await httpService.post(`${route}/email/template/bulk`, { ids });
  return response;
};
