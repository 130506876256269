import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  error: {},
  emails: {},
};

export const emailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getEmailsSuccess(state, action) {
      state.isLoading = false;
      state.emails = action.payload;
    },
  },
});

export default emailSlice.reducer;
