import classes from "./Transaction.module.css";
import { TRANSACTION_ARRAY } from "../../../constants";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useCallback, useState } from "react";
import { errorHandler } from "../../../utils/errorHandler";
import { useNavigate } from "react-router";
import FullScreenLoader from "../../../components/UI/FullScreenLoader/FullScreenLoader";
import ConfirmDialog from "../../../components/UI/ConfirmDialog/ConfirmDialog";
import CheckInput from "../../../components/UI/CheckInput/input";
import { toast } from "react-toastify";

const Transaction = () => {
  const BTN_ARRAY = [
    {
      text: "All",
      count: 0,
    },
    {
      text: "Credit",
      count: 0,
    },
    {
      text: "Debit",
      count: 0,
    },
  ];

  const [currentPage, setCurrentPage] = useState("All");

  const [isOpen, setIsOpen] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [unSelectedItems, setUnSelectedItems] = useState([]);
  const navigate = useNavigate();

  const handleCheckAll = useCallback((e) => {
    setCheckAll(e.target.checked);
    setUnSelectedItems([])
    setSelectedItems([])
  }, []);

  const handleCheck = useCallback((id) => {
    if ((checkAll || selectedItems.includes(id))) {
      if (!unSelectedItems.includes(id)) {
        setUnSelectedItems(prev => [...prev, id]);
      }
      if (selectedItems.includes(id)) {
        setSelectedItems(prev => prev.filter(p => p !== id));
      }
    }
    if ((!checkAll || unSelectedItems.includes(id))) {
      if (unSelectedItems.includes(id)) {
        setUnSelectedItems(prev => prev.filter(p => p !== id));
      }
      if (!selectedItems.includes(id)) {
        setSelectedItems(prev => [...prev, id]);
      }
    }
  }, [checkAll, selectedItems, unSelectedItems]);

  const handleDeleteMultiple = useCallback(async () => {
    setLoading(true)
    try {
      if (checkAll || selectedItems.length > 0) {
        // const items = checkAll ? handleFilterItems(transactions?.records, unSelectedItems) : selectedItems;
        // const { data } = await deleteMultipleClientApi(items);
        // console.log({ data })
        // if (data) {
        //   toast.success('Records deleted successfully');
        // }
      } else {
        toast.error("No record selected for this operation")
      }
    } catch (error) {
      errorHandler(error, error.errors ? Object.values(error.errors)[0] : '')
    }
    setLoading(false)
  }, [checkAll, selectedItems])

  const handleSelect = useCallback(async (id = '') => {
    setSelectedItem(id)
    setIsOpen(true)
  }, [])

  const handleDelete = useCallback(async () => {
    setLoading(true)
    try {
      if (selectedItem) {
        // const { data } = await deleteClientApi(selectedItem);
        // if (data) {
        //   toast.success('Record deleted successfully');
        //   setTimeout(() => {
        //     navigate(0)
        //   }, 500);
        // }
      }
    } catch (error) {
      errorHandler(error, error.errors ? Object.values(error.errors)[0] : '')
    }
    setLoading(false)
  }, [navigate, selectedItem])

  return (
    <div>
      {(loading) && <FullScreenLoader />}

      <ConfirmDialog confirmText="Delete" handleConfirm={selectedItem ? handleDelete : handleDeleteMultiple} setIsOpen={setIsOpen} isOpen={isOpen} desc={`Are you sure you want to delete ${selectedItem ? 'this transaction' : 'these transactions'}?`} />

      <h2>Transaction</h2>
      <div className={`flex  mt-sm ${classes.btn} !justify-start gap-10`}>
        {BTN_ARRAY.map((item, i) => (
          <button
            key={`btn-${i}`}
            onClick={() => setCurrentPage(item.text)}
            className={`py-3 ${currentPage === item.text ? classes.active : ""}`}
          >
            {item.text} {item.count}
          </button>
        ))}
      </div>

      <div className={`mt-md ${classes.table}`}>
        <div className={`flex flex-col md:flex-row md:items-center gap-4 mb-md ${classes.btn}`}>
          <h2>Transaction Manager</h2>
          <input placeholder="Search" />
          {/* <div className={`flex flex-col sm:flex-row sm:items-center gap-4 ${classes.otherbutton}`}>
            <Button variant="white">Delete</Button>
          </div> */}
        </div>


        <DataTable
          emptyMessage="No record found"
          rows={10}
          paginator={TRANSACTION_ARRAY.length > 10}
          value={TRANSACTION_ARRAY || []}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: '100%' }}
        >
          <Column className="pr-3 text-center"
            field="_id"
            header={
              <CheckInput type="checkbox" className={`w-[18px] h-[18px] rounded-s`} isIndeterminate={checkAll && unSelectedItems.length > 0} checked={checkAll} onChange={handleCheckAll} />}
            body={(item) => (
              <div className="text-left">
                <CheckInput type="checkbox" className="w-[18px] h-[18px] rounded-s" checked={(checkAll || selectedItems.includes(item._id)) && !unSelectedItems.includes(item._id)} onChange={() => handleCheck(item._id)} />
              </div>
            )}
          />
          <Column className="p-5 text-center"
            field="name"
            header="NAME"
            body={(item) => (
              <div className="text-left">
                <p>{item.name}</p>
                <small>{item.email}</small>
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="reference"
            header="REFERENCE NUMBER/ID"
            body={(item) => (
              <div>
                {item.reference}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="address"
            header="ADDRESS"
            body={(item) => (
              <div className={classes.subject}>{item.address}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="PHONE NUMBER"
            body={(item) => (
              <div>
                {item.phone}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="amount"
            header="AMOUNT"
            body={(item) => (
              <div>
                {item.amount}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="date"
            header="DATE"
            body={(item) => (
              <div>
                {item.date}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="time"
            header="TIME"
            body={(item) => (
              <div>
                {item.time}
              </div>)}
            sortable
          />
        </DataTable>
      </div>
    </div>
  );
};

export default Transaction;
