import Button from "../../../../components/UI/Button/Button";
import RadioInput from "../../../../components/UI/RadioInput/input";
import classes from "./index.module.css";
import { Formik } from "formik";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { errorHandler } from "../../../../utils/errorHandler";
import { addUserGroupApi, updateUserGroupApi } from "../../../../apis";
import CheckInput from "../../../../components/UI/CheckInput/input";
import { useCallback, useMemo, useState } from "react";
import { createUserGroupIdentifier } from "../../../../utils";

const OPTIONS = [
  {
    title: "Roles",
    option: [{ title: "Select All", value: "all" }],
  },
  {
    title: "Dashboard",
    option: [
      { title: "Dashboard Summary", value: "dashboard_summary" },
      { title: "Activity Summary", value: "activity_summary" },
      { title: "Job Summary", value: "job_summary" }
    ],
  },
  {
    title: "Talent User Management",
    option: [
      { title: "Add", value: "talent_add" },
      { title: "Edit", value: "talent_edit" },
      { title: "Block", value: "talent_block" },
      { title: "Email", value: "talent_email" },
      { title: "Delete", value: "talent_delete" },
      { title: "View/Access", value: "talent_view" }
    ],
  },
  {
    title: "Client User Management",
    option: [
      { title: "Add", value: "client_add" },
      { title: "Edit", value: "client_edit" },
      { title: "Block", value: "client_block" },
      { title: "Email", value: "client_email" },
      { title: "Delete", value: "client_delete" },
      { title: "View/Access", value: "client_view" }
    ],
  },
  {
    title: "Admin User Management",
    option: [
      { title: "Add", value: "admin_add" },
      { title: "Edit", value: "admin_edit" },
      { title: "Block", value: "admin_block" },
      { title: "Email", value: "admin_email" },
      { title: "Delete", value: "admin_delete" },
      { title: "View/Access", value: "admin_view" },
      { title: "List/View Admin user group", value: "view_admin_user_group" },
      { title: "Add Admin user group", value: "add_admin_user_group" },
      { title: "Edit Admin user group", value: "edit_admin_user_group" },
      { title: "Delete Admin user group", value: "delete_admin_user_group" },
    ],
  },
  {
    title: "Jobs",
    option: [
      { title: "Create Job", value: "job_add" },
      { title: "Edit", value: "job_edit" },
      { title: "Delete", value: "job_delete" },
      { title: "View", value: "job_view" },
    ],
  },
  {
    title: "Subscriptions",
    option: [
      { title: "Edit", value: "subscription_edit" },
      { title: "Delete", value: "subscription_delete" },
      { title: "View", value: "subscription_view" }
    ],
  },
  {
    title: "Transactions",
    option: [
      { title: "Edit", value: "transaction_edit" },
      { title: "Delete", value: "transaction_delete" },
      { title: "View", value: "transaction_view" }
    ],
  },
  {
    title: "Tests",
    option: [
      { title: "Add", value: "test_add" },
      { title: "Edit", value: "test_edit" },
      { title: "Delete", value: "test_delete" },
      { title: "View", value: "test_view" }
    ],
  },
  {
    title: "Emails",
    option: [
      { title: "Add", value: "email_add" },
      { title: "Edit", value: "email_edit" },
      { title: "Delete", value: "email_delete" },
      { title: "View", value: "email_view" }
    ],
  },
  {
    title: "Supports",
    option: [
      { title: "Add", value: "support_add" },
      { title: "Edit", value: "support_edit" },
      { title: "View", value: "support_view" }
    ],
  },
];

const AddNewGroup = ({ title = "Add" }) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const allOptions = useMemo(() => OPTIONS.flatMap(option => option.option.map(o => o.value)).filter(val => val !== "all"), []);

  const [checkAll, setCheckAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState(state?.roles || []);

  const handleCheckAll = useCallback((e) => {
    setCheckAll(e.target.checked);

    if (allOptions.length === selectedItems.length) {
      setSelectedItems([])
    } else {
      setSelectedItems(allOptions)
    }
  }, [allOptions, selectedItems.length]);

  const handleCheck = useCallback((value) => {
    if (selectedItems.includes(value)) {
      setSelectedItems(prev => prev.filter(p => p !== value));
      setCheckAll(false)
    } else {
      setSelectedItems(prev => ([...prev, value]));
    }
  }, [selectedItems]);


  return (
    <Formik
      initialValues={{
        userGroupName: state?.userGroupName || "",
        status: state?.status || "Active",
        roles: selectedItems,
        identifier: state?.identifier,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        try {
          const { data } = title === "Add"
            ? await addUserGroupApi({
              ...values, roles: selectedItems, identifier: createUserGroupIdentifier(values?.userGroupName)
            })
            : await updateUserGroupApi(state._id, {
              ...values, roles: selectedItems
            });
          if (data) {
            toast.success(data.message || `${title} was successful`);
            navigate(-1)
          }
        } catch (error) {
          errorHandler(error, Object.values(error.errors)[0])
        }
        setSubmitting(false)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        getFieldProps,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} className={classes.container}>
          <h2>{title} Admin User Group</h2>
          <div className={classes.container}>
            <div>
              <label>
                User Group Name*
                <input required type="text" placeholder="Customer Support" className="mt-sm" {...getFieldProps("userGroupName")} />
              </label>
            </div>
            <div className={classes.radios}>
              <p>Status</p>
              <div>
                <RadioInput
                  text="Active"
                  value="Active"
                  name="status"
                  checked={values.status === "Active"}
                  onChange={(e) => {
                    if (e.target.checked)
                      setFieldValue('status', e.target.value);
                  }}
                />

                <RadioInput
                  text="Inactive"
                  value="Inactive"
                  name="status"
                  checked={values.status === "Inactive"}
                  onChange={(e) => {
                    if (e.target.checked)
                      setFieldValue('status', e.target.value);
                  }}
                />
              </div>
            </div>
            {OPTIONS.map((item, index) => (
              <div key={index} className={classes.group}>
                <p>{item.title}</p>
                <div className={classes.checks}>
                  {item.option.map((item, idx) => (
                    <label className="flex items-center gap-2" key={idx}>
                      <CheckInput type="checkbox" className={`w-[18px] h-[18px] rounded-s !m-0 !px-3 !py-0`} checked={checkAll || allOptions.length === selectedItems.length || selectedItems.includes(item.value)} onChange={item.value === "all" ? handleCheckAll : () => handleCheck(item.value)} />
                      {/* <input type="checkbox" /> */}
                      {item.title}
                    </label>
                  ))}
                </div>
              </div>
            ))}
            <div className={classes.btn}>
              <Button variant="white" isLink to="/dashboard/admin/user-group">
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting} variant="blue">Submit</Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddNewGroup;
